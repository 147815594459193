import React, { useEffect, useState } from 'react';

import Swal from 'sweetalert2/dist/sweetalert2.js';

const SwalBs = Swal.mixin({
    customClass: {
        popup: 'rounded-lg',
        confirmButton: 'btn btn-success rounded-lg font-weight-bold text-uppercase px-9 py-4 mx-auto',
        cancelButton: 'btn btn-danger rounded-lg font-weight-bold text-uppercase px-9 py-4 mx-auto'
    },
    buttonsStyling: false
});

export function UserInformationEditPage(props) {
    const [ wallet, setWallet ] = useState({});

    useEffect(() => {
        let walletobject = {};
        for (const index in props.wallet) {
            walletobject[props.wallet[index]['id']] = '';
        }
        setWallet(walletobject);
    }, []);

    const handleChange = (value, key) => {
        let changewallet = wallet;
        changewallet[key] = value;
        setWallet(changewallet);
    };

    const handleUpdate = () => {
        let param = [];
        for (const key in wallet) {
            if (wallet[key] !== '') {
                param.push({
                    accountid: key,
                    value: wallet[key]
                });
            }
        }
        if (param.length === 0) {
            SwalBs.fire({
                // title: 'ยกเลิกคำสั่ง',
                html: 'กรุณากรอกรายละเอียดที่ต้องการแก้ไขให้เรียบร้อย',
                icon: 'warning',
                // showCancelButton: true,
                confirmButtonText: 'รับทราบ',
                allowOutsideClick: false,
                allowEscapeKey: false,
                allowEnterKey: false
            });
            return;
        }
        props.btnUpdate(param, props.info);
    };

    return (
        <div className='row m-0'>
            <div className='col-12 p-0 mb-0'>
                {props.wallet.map((object, index, arr) => {
                    return (
                        <div className='form-group' key={index}>
                            <label>
                                หมายเลขกระเป๋า{' '}
                                {object.account_type === 'bnb' ? (
                                    'BNB'
                                ) : object.account_type === 'starverse' ? (
                                    'StarVerse'
                                ) : null}
                            </label>
                            <input
                                type='text'
                                className='form-control'
                                defaultValue={object.withdraw_address}
                                onChange={(e) => handleChange(e.target.value, object.id)}
                            />
                        </div>
                    );
                })}
            </div>
            <div className='col-12 p-0 text-right'>
                <button type='button' className='btn btn-primary rounded-lg px-10' onClick={handleUpdate}>
                    แก้ไข
                </button>
            </div>
        </div>
    );
}
