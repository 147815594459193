import React, { Suspense } from 'react';
import { Redirect, Switch, useLocation, useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { LayoutSplashScreen, ContentRoute } from '../_metronic/layout';
// import {BuilderPage} from "./pages/BuilderPage";
// import {MyPage} from "./pages/MyPage";
import { DashboardPage } from './pages/DashboardPage';

import { UserInformationMain } from './pages/customs/user/information/Main';

import { ReportDonateMain } from './pages/customs/report/donate/Main';
import { ReportWithdrawMain } from './pages/customs/report/withdraw/Main';

import config from './config';

// const GoogleMaterialPage = lazy(() =>
//   import("./modules/GoogleMaterialExamples/GoogleMaterialPage")
// );
// const ReactBootstrapPage = lazy(() =>
//   import("./modules/ReactBootstrapExamples/ReactBootstrapPage")
// );
// const ECommercePage = lazy(() =>
//   import("./modules/ECommerce/pages/eCommercePage")
// );

function usePageViews() {
    const { user } = useSelector((state) => state.auth);
    let location = useLocation();
    let history = useHistory();
    React.useEffect(
        () => {
            // console.log([ 'pageview', location.pathname ]);
            if (new Date() >= new Date(user.token_expire)) {
                history.push('/logout?expire');
            } else {
                fetch(config.crypto_url + 'user/validate', {
                    method: 'post',
                    headers: {
                        Accept: 'application/json',
                        'Content-Type': 'application/json'
                        // secret: config.secret,
                    },
                    body: JSON.stringify({
                        token: user.token,
                        username: user.username
                    })
                })
                    .then((response) => response.json())
                    .then((result) => {
                        if (result.responsecode !== 0) {
                            history.push('/logout?expire');
                        }
                    })
                    .catch((error) => {
                        history.push('/logout?expire');
                    });
            }
        },
        [ location ]
    );
}

export default function BasePage() {
    // useEffect(() => {
    //   console.log('Base page');
    // }, []) // [] - is required if you need only one call
    // https://reactjs.org/docs/hooks-reference.html#useeffect
    usePageViews();

    return (
        <Suspense fallback={<LayoutSplashScreen />}>
            <Switch>
                {
                    /* Redirect from root URL to /dashboard. */
                    <Redirect exact from='/' to='/dashboard' />
                }
                <ContentRoute path='/dashboard' component={DashboardPage} />
                <ContentRoute path='/user/information' component={UserInformationMain} />
                <ContentRoute path='/report/donate' component={ReportDonateMain} />
                <ContentRoute path='/report/withdraw' component={ReportWithdrawMain} />
                {/* <ContentRoute path="/builder" component={BuilderPage}/>
                <ContentRoute path="/my-page" component={MyPage}/>
                <Route path="/google-material" component={GoogleMaterialPage}/>
                <Route path="/react-bootstrap" component={ReactBootstrapPage}/>
                <Route path="/e-commerce" component={ECommercePage}/> */}
                <Redirect to='error/error-v1' />
            </Switch>
        </Suspense>
    );
}
