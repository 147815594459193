import React, { useEffect, useState } from 'react';

import { useSelector } from 'react-redux';

import { Modal } from 'react-bootstrap';

import Swal from 'sweetalert2/dist/sweetalert2.js';

import { ReportWithdrawTable } from './components';

import config from '../../../../config';

const SwalBs = Swal.mixin({
    customClass: {
        popup: 'rounded-lg',
        confirmButton: 'btn btn-success rounded-lg font-weight-bold text-uppercase px-9 py-4 mx-auto',
        cancelButton: 'btn btn-danger rounded-lg font-weight-bold text-uppercase px-9 py-4 mx-auto'
    },
    buttonsStyling: false
});

export function ReportWithdrawMain(props) {
    const { user } = useSelector((state) => state.auth);

    const [ loading, setLoading ] = useState(false);
    const [ orderlist, setOrderlist ] = useState({});
    const [ oid, setOid ] = useState('');
    const [ userinfo, setUserInfo ] = useState({});

    const [ showModal, setShow ] = useState(false);
    const [ showUserModal, setShowUser ] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const handleUserClose = () => setShowUser(false);
    const handleUserShow = () => setShowUser(true);

    useEffect(() => {
        btnSearch();
    }, []);

    const btnSearch = () => {
        setOrderlist({});
        setLoading(true);
        fetch(config.crypto_url + 'admin/account/get-transaction-withdraw', {
            method: 'post',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json'
                // secret: config.secret,
            },
            body: JSON.stringify({
                token: user.token
            })
        })
            .then((response) => response.json())
            .then((result) => {
                setLoading(false);
                setOrderlist(result);
            })
            .catch((error) => {
                setLoading(false);
            });
    };

    const btnApprove = () => {
        if (oid === '') {
            SwalBs.fire({
                html: 'กรุณากรอกหมายเลขรายการที่ต้องการอนุมัติให้เรียบร้อย แล้วลองใหม่อีกครั้ง',
                icon: 'warning',
                confirmButtonText: 'รับทราบ',
                allowOutsideClick: false,
                allowEscapeKey: false,
                allowEnterKey: false
            });
            return;
        }
        setLoading(true);
        fetch(config.crypto_url + 'admin/account/complete-transaction', {
            method: 'post',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json'
                // secret: config.secret,
            },
            body: JSON.stringify({
                token: user.token,
                id: oid
            })
        })
            .then((response) => response.json())
            .then((result) => {
                setLoading(false);
                if (result.responsecode === 0) {
                    SwalBs.fire({
                        html: 'ดำเนินการเรียบร้อย',
                        icon: 'success',
                        confirmButtonText: 'รับทราบ',
                        allowOutsideClick: false,
                        allowEscapeKey: false,
                        allowEnterKey: false
                    }).then((event) => {
                        if (event.isConfirmed) {
                            setOid('');
                            handleClose();
                            btnSearch();
                        }
                    });
                } else {
                    SwalBs.fire({
                        html: 'มีบางอย่างผิดพลาด กรุณาลองใหม่อีกครั้ง',
                        icon: 'warning',
                        confirmButtonText: 'รับทราบ',
                        allowOutsideClick: false,
                        allowEscapeKey: false,
                        allowEnterKey: false
                    });
                    return;
                }
            })
            .catch((error) => {
                setLoading(false);
                SwalBs.fire({
                    html: 'มีบางอย่างผิดพลาด กรุณาลองใหม่อีกครั้ง',
                    icon: 'error',
                    confirmButtonText: 'รับทราบ',
                    allowOutsideClick: false,
                    allowEscapeKey: false,
                    allowEnterKey: false
                });
                return;
            });
    };

    const getUserInfo = (transactionid) => {
        setLoading(true);
        fetch(config.crypto_url + 'admin/account/view', {
            method: 'post',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json'
                // secret: config.secret,
            },
            body: JSON.stringify({
                token: user.token,
                id: transactionid
            })
        })
            .then((response) => response.json())
            .then((result) => {
                setLoading(false);
                if (result.responsecode === 0) {
                    setUserInfo(result);
                    handleUserShow();
                } else {
                    SwalBs.fire({
                        html: `${result.responsecode}! มีบางอย่างผิดพลาด กรุณาลองใหม่อีกครั้ง`,
                        icon: 'warning',
                        confirmButtonText: 'รับทราบ',
                        allowOutsideClick: false,
                        allowEscapeKey: false,
                        allowEnterKey: false
                    });
                    return;
                }
                // setOrderlist(result);
            })
            .catch((error) => {
                setLoading(false);
                SwalBs.fire({
                    html: 'มีบางอย่างผิดพลาด กรุณาลองใหม่อีกครั้ง',
                    icon: 'error',
                    confirmButtonText: 'รับทราบ',
                    allowOutsideClick: false,
                    allowEscapeKey: false,
                    allowEnterKey: false
                });
                return;
            });
    };

    return (
        <div>
            {loading ? (
                <div className='d-flex flex-column flex-root' id='loading-section'>
                    <div className='row justify-content-center align-items-center overlay p-0 m-0' id='overlay'>
                        <div className='col col-sm-6 col-lg-12 h-100 p-0'>
                            <div className='bounce col-12'>
                                <div className='bounce1' />
                                <div className='bounce2' />
                                <div className='bounce3' />
                            </div>
                            <h1 className='col loading-txt'>กรุณารอสักครู่</h1>
                        </div>
                    </div>
                </div>
            ) : (
                <div className='row main-page'>
                    <div className='col-12 row m-0 mb-5 justify-content-end'>
                        <button type='button' className='btn btn-primary rounded-lg' onClick={handleShow}>
                            <i className='fas fa-check' /> อนุมัติรายการ
                        </button>
                    </div>
                    <ReportWithdrawTable orderlist={orderlist} getUserInfo={getUserInfo} />
                </div>
            )}

            <Modal show={showModal} onHide={handleClose} size='xl' centered aria-labelledby='approve-withdraw-modal'>
                <Modal.Header closeButton>
                    <Modal.Title id='approve-withdraw-modal' className='col-11 p-0'>
                        อนุมัติรายการ
                    </Modal.Title>
                    <button type='button' className='close col-1 text-right pr-3' onClick={handleClose}>
                        <i className='ki ki-close' />
                    </button>
                </Modal.Header>
                <Modal.Body>
                    <div className='row d-flex justify-content-center'>
                        <div className='col-sm-12 d-flex justify-content-between py-5'>
                            <div className='row form-group col-12 p-0 m-0'>
                                <div className='col-12 col-md-3 form-group'>
                                    <div className='form-group mb-0'>
                                        <label>หมายเลขรายการที่ต้องการอนุมัติ</label>
                                        <input
                                            type='text'
                                            className='form-control rounded-lg'
                                            value={oid}
                                            onChange={(e) => setOid(e.target.value)}
                                            onKeyPress={(e) => {
                                                if (e.key === 'Enter') {
                                                    btnApprove();
                                                }
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='col-sm-12 d-flex justify-content-end pt-5'>
                            <button type='button' className='btn btn-primary rounded-lg' onClick={btnApprove}>
                                <i className='fas fa-check' /> ยืนยัน
                            </button>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>

            <Modal show={showUserModal} onHide={handleUserClose} size='xl' centered aria-labelledby='user-info-modal'>
                <Modal.Header closeButton>
                    <Modal.Title id='user-info-modal' className='col-11 p-0'>
                        รายละเอียด
                    </Modal.Title>
                    <button type='button' className='close col-1 text-right pr-3' onClick={handleUserClose}>
                        <i className='ki ki-close' />
                    </button>
                </Modal.Header>
                <Modal.Body>
                    <div className='row d-flex justify-content-center'>
                        {Object.keys(userinfo).length <= 0 ? (
                            <div className='row justify-content-center align-items-center bg-white cart-box min-h-400px'>
                                <div className='col-12 text-center'>
                                    <i className='text-dark-75 fas fa-exclamation-circle fa-5x' />
                                    <h3 className='col mt-5'>มีบางอย่างผิดพลาด กรุณาลองใหม่อีกครั้ง</h3>
                                </div>
                            </div>
                        ) : (
                            <div className='col-sm-12 d-flex justify-content-between py-5'>
                                {userinfo.responsecode !== 0 ? (
                                    <div className='row justify-content-center align-items-center bg-white cart-box min-h-400px'>
                                        <div className='col-12 text-center'>
                                            <i className='text-dark-75 fas fa-exclamation-circle fa-5x' />
                                            <h3 className='col mt-5'>
                                                ไม่พบข้อมูลที่ต้องการค้นหาจากรายละเอียดที่ต้องการค้นหา
                                                กรุณาลองใหม่อีกครั้ง
                                            </h3>
                                        </div>
                                    </div>
                                ) : (
                                    <div className='row form-group col-12 p-0 m-0'>
                                        <div className='col-12 form-group row mx-0'>
                                            <label className='col-form-label col-3'>ชื่อ</label>
                                            <div className='col-9 form-control border-0 text-right'>
                                                {userinfo.detail.first_name}
                                            </div>
                                        </div>
                                        <div className='col-12 form-group row mx-0'>
                                            <label className='col-form-label col-3'>นามสกุล</label>
                                            <div className='col-9 form-control border-0 text-right'>
                                                {userinfo.detail.last_name}
                                            </div>
                                        </div>
                                        <div className='col-12 form-group row mx-0'>
                                            <label className='col-form-label col-3'>เลขบัตรประชาชน</label>
                                            <div className='col-9 form-control border-0 text-right'>
                                                {userinfo.detail.personal_id}
                                            </div>
                                        </div>
                                        <div className='col-12 form-group row mx-0'>
                                            <label className='col-form-label col-3'>เบอร์ติดต่อ</label>
                                            <div className='col-9 form-control border-0 text-right'>
                                                {userinfo.detail.contact_number}
                                            </div>
                                        </div>
                                        <div className='col-12 form-group row mx-0'>
                                            <label className='col-form-label col-3'>วันที่สมัครสมาชิก</label>
                                            <div className='col-9 form-control border-0 text-right'>
                                                {userinfo.detail.created_date}
                                            </div>
                                        </div>
                                    </div>
                                )}
                            </div>
                        )}
                    </div>
                </Modal.Body>
            </Modal>
        </div>
    );
}
