import React, { useEffect, useState } from 'react';
// import { Link } from "react-router-dom";
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import * as auth from '../_redux/authRedux';
// import { login } from "../_redux/authCrud";

import config from '../../../config';

/*
  INTL (i18n) docs:
  https://github.com/formatjs/react-intl/blob/master/docs/Components.md#formattedmessage
*/

/*
  Formik+YUP:
  https://jaredpalmer.com/formik/docs/tutorial#getfieldprops
*/

const initialValues = {
    username: '',
    password: ''
};

function Login(props) {
    // const { intl } = props;
    const [ loading, setLoading ] = useState(false);
    const [ expire, setExpire ] = useState(false);
    const LoginSchema = Yup.object().shape({
        username: Yup.string().required('จำเป็นต้องกรอก'),
        password: Yup.string().required('จำเป็นต้องกรอก')
    });

    useEffect(() => {
        // console.log(props);
        if (props.location.pathname.includes('/expire')) {
            setExpire(true);
        }
    }, []);

    const enableLoading = () => {
        setLoading(true);
    };

    const disableLoading = () => {
        setLoading(false);
    };

    const getInputClasses = (fieldname) => {
        if (formik.touched[fieldname] && formik.errors[fieldname]) {
            return 'is-invalid';
        }

        // if (formik.touched[fieldname] && !formik.errors[fieldname]) {
        //   return "is-valid";
        // }

        return '';
    };

    const formik = useFormik({
        initialValues,
        validationSchema: LoginSchema,
        onSubmit: (values, { setStatus, setSubmitting }) => {
            enableLoading();
            fetch(config.crypto_url + 'admin/user/login', {
                method: 'post',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json'
                    // secret: config.secret,
                },
                body: JSON.stringify({
                    username: values.username,
                    password: values.password
                })
            })
                .then((response) => response.json())
                .then((result) => {
                    disableLoading();
                    // console.log(result);
                    if (result.responsecode === 0) {
                        let user = result;
                        user['username'] = values.username;
                        user['password'] = values.password;
                        let d = new Date();
                        d.setDate(d.getDate() + 1);
                        user['token_expire'] = d;
                        props.fulfillUser(user);
                    } else {
                        setSubmitting(false);
                        setStatus('มีบางอย่างผิดพลาด กรุณาลองใหม่อีกครั้ง');
                    }
                    // if (result.responsecode === 100) {
                    //     setSubmitting(false);
                    //     setStatus('มีบางอย่างผิดพลาด กรุณาลองใหม่อีกครั้ง');
                    // } else if (result.responsecode === 101) {
                    //     setSubmitting(false);
                    //     setStatus('ไม่พบบัญชีของคุณ กรุณาลองใหม่อีกครั้ง');
                    // } else if (result.responsecode === 102) {
                    //     setSubmitting(false);
                    //     setStatus('บัญชีของคุณไม่มีสิทธิในการใช้งานระบบ');
                    // } else if (result.responsecode === 103) {
                    //     setSubmitting(false);
                    //     setStatus('ผิดพลาดมีปัญหาระหว่างการเข้าสู่ระบบ กรุณาลองใหม่อีกครั้ง');
                    // } else if (result.responsecode === 104) {
                    //     setSubmitting(false);
                    //     setStatus('บัญชีของคุณไม่มีสิทธิในการใช้งานระบบ');
                    // } else {
                    //     let d = new Date();
                    //     // d.setSeconds(d.getSeconds() + 10);
                    //     d.setMinutes(d.getMinutes() + 60);
                    //     // d.setMinutes(d.getMinutes() + 60);
                    //     // d.setYear(d.getFullYear() + 1);
                    //     result['login_time'] = d.toISOString();
                    //     result['username'] = values.username;
                    //     result['password'] = values.password;
                    //     setSubmitting(false);
                    //     props.login(result);
                    // }
                })
                .catch((error) => {
                    disableLoading();
                    setSubmitting(false);
                    setStatus('มีบางอย่างผิดพลาด กรุณาลองใหม่อีกครั้ง');
                });
        }
    });

    return (
        <div className='login-form login-signin' id='kt_login_signin_form'>
            {/* begin::Head */}
            <div className='text-center mb-10 mb-lg-20'>
                <h3 className='font-size-h1 text-brown'>ระบบหลังบ้าน P2Plearning</h3>
                <p className='text-muted font-weight-bold'>กรุณากรอกชื่อผู้ใช้และรหัสผ่านของคุณ</p>
            </div>
            {/* end::Head */}

            {/*begin::Form*/}
            <form onSubmit={formik.handleSubmit} className='form fv-plugins-bootstrap fv-plugins-framework'>
                {formik.status && (
                    <div className='mb-10 alert alert-custom alert-light-danger alert-dismissible'>
                        <div className='alert-text font-weight-bold'>{formik.status}</div>
                    </div>
                )}
                {expire && (
                    <div className='mb-10 alert alert-custom alert-light-danger alert-dismissible rounded-lg'>
                        <div className='alert-text font-weight-bold'>เซสชันหมดอายุ โปรดเข้าสู่ระบบอีกครั้งเพื่อต่ออายุเซสชัน</div>
                    </div>
                )}
                {/* {formik.status ? (
          <div className="mb-10 alert alert-custom alert-light-danger alert-dismissible">
            <div className="alert-text font-weight-bold">{formik.status}</div>
          </div>
        ) : (
          <div className="mb-10 alert alert-custom alert-light-info alert-dismissible">
            <div className="alert-text ">
              Use account <strong>admin@demo.com</strong> and password{" "}
              <strong>demo</strong> to continue.
            </div>
          </div>
        )} */}

                <div className='form-group fv-plugins-icon-container'>
                    <label className='font-size-h6 font-weight-bolder'>ชื่อผู้ใช้งาน</label>
                    <input
                        type='text'
                        className={`form-control form-control-solid h-auto py-5 px-6 rounded-lg ${getInputClasses(
                            'username'
                        )}`}
                        name='username'
                        {...formik.getFieldProps('username')}
                    />
                    {formik.touched.username && formik.errors.username ? (
                        <div className='fv-plugins-message-container'>
                            <div className='fv-help-block'>{formik.errors.username}</div>
                        </div>
                    ) : null}
                </div>
                <div className='form-group fv-plugins-icon-container'>
                    <label className='font-size-h6 font-weight-bolder'>รหัสผ่าน</label>
                    <input
                        type='password'
                        className={`form-control form-control-solid h-auto py-5 px-6 rounded-lg ${getInputClasses(
                            'password'
                        )}`}
                        name='password'
                        {...formik.getFieldProps('password')}
                    />
                    {formik.touched.password && formik.errors.password ? (
                        <div className='fv-plugins-message-container'>
                            <div className='fv-help-block'>{formik.errors.password}</div>
                        </div>
                    ) : null}
                </div>
                <div className='form-group d-flex flex-wrap justify-content-end align-items-center'>
                    <button
                        id='kt_login_signin_submit'
                        type='submit'
                        disabled={formik.isSubmitting}
                        className={`btn btn-primary font-weight-bold px-9 py-4 my-3 rounded-lg`}
                    >
                        <span>เข้าสู่ระบบ</span>
                        {loading && <span className='ml-3 spinner spinner-white' />}
                    </button>
                </div>
            </form>
            {/*end::Form*/}
        </div>
    );
}

export default injectIntl(connect(null, auth.actions)(Login));
