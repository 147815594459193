import React, { useState } from 'react';

import { useSelector } from 'react-redux';

import { Modal } from 'react-bootstrap';

import Swal from 'sweetalert2/dist/sweetalert2.js';

import { UserInformationDetailPage, UserInformationMainPage, UserInformationTablePage } from './components';

import config from '../../../../config';

const SwalBs = Swal.mixin({
    customClass: {
        popup: 'rounded-lg',
        confirmButton: 'btn btn-success rounded-lg font-weight-bold text-uppercase px-9 py-4 mx-auto',
        cancelButton: 'btn btn-danger rounded-lg font-weight-bold text-uppercase px-9 py-4 mx-auto'
    },
    buttonsStyling: false
});

export function UserInformationMain(props) {
    const { user } = useSelector((state) => state.auth);

    const [ page, setPage ] = useState('main');
    const [ tab, setTab ] = useState('information');
    const [ loading, setLoading ] = useState(false);
    const [ init, setInit ] = useState(true);
    const [ keyword, setKeyword ] = useState('');
    const [ response, setResponse ] = useState({});
    const [ userinfo, setUserInfo ] = useState({});
    const [ walletinfo, setWalletInfo ] = useState([]);
    const [ transaction, setTransaction ] = useState({});

    const [ showModal, setShow ] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const btnSearch = () => {
        if (keyword === '') {
            SwalBs.fire({
                // title: 'ยกเลิกคำสั่ง',
                html: 'กรุณากรอกรายละเอียดที่ต้องการค้นหาให้เรียบร้อย',
                icon: 'warning',
                // showCancelButton: true,
                confirmButtonText: 'รับทราบ',
                allowOutsideClick: false,
                allowEscapeKey: false,
                allowEnterKey: false
            });
            return;
        }
        setLoading(true);
        fetch(config.crypto_url + 'admin/user/find', {
            method: 'post',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json'
                // secret: config.secret,
            },
            body: JSON.stringify({
                token: user.token,
                search: keyword,
                group_id: '2'
            })
        })
            .then((response) => response.json())
            .then((result) => {
                setLoading(false);
                setResponse(result);
                setInit(false);
            })
            .catch((error) => {
                setLoading(false);
            });
    };

    const btnView = (info) => {
        setLoading(true);
        fetch(config.crypto_url + 'admin/account/find', {
            method: 'post',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json'
                // secret: config.secret,
            },
            body: JSON.stringify({
                token: user.token,
                userid: info.id.toString()
            })
        })
            .then((response) => response.json())
            .then((result) => {
                setLoading(false);
                if (result.responsecode === 0) {
                    setUserInfo(info);
                    setWalletInfo(result.wallet);
                    setPage('info');
                } else {
                    SwalBs.fire({
                        html: 'มีบางอย่างผิดพลาด กรุณาลองใหม่อีกครั้ง',
                        icon: 'warning',
                        confirmButtonText: 'รับทราบ',
                        allowOutsideClick: false,
                        allowEscapeKey: false,
                        allowEnterKey: false
                    });
                    return;
                }
            })
            .catch((error) => {
                setLoading(false);
                SwalBs.fire({
                    html: 'มีบางอย่างผิดพลาด กรุณาลองใหม่อีกครั้ง',
                    icon: 'error',
                    confirmButtonText: 'รับทราบ',
                    allowOutsideClick: false,
                    allowEscapeKey: false,
                    allowEnterKey: false
                });
                return;
            });
    };

    const btnUpdate = (item) => {
        setLoading(true);
        fetch(config.crypto_url + 'admin/account/update', {
            method: 'post',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json'
                // secret: config.secret,
            },
            body: JSON.stringify({
                token: user.token,
                update: item
            })
        })
            .then((response) => response.json())
            .then((result) => {
                setLoading(false);
                if (result.responsecode === 0) {
                    SwalBs.fire({
                        html: 'ดำเนินการแก้ไขเรียบร้อย',
                        icon: 'success',
                        confirmButtonText: 'รับทราบ',
                        allowOutsideClick: false,
                        allowEscapeKey: false,
                        allowEnterKey: false
                    }).then((result) => {
                        /* Read more about isConfirmed, isDenied below */
                        if (result.isConfirmed) {
                            setPage('main');
                            setTab('information');
                            setUserInfo({});
                            setWalletInfo([]);
                        }
                    });
                } else {
                    SwalBs.fire({
                        html: 'มีบางอย่างผิดพลาด กรุณาลองใหม่อีกครั้ง',
                        icon: 'warning',
                        confirmButtonText: 'รับทราบ',
                        allowOutsideClick: false,
                        allowEscapeKey: false,
                        allowEnterKey: false
                    });
                    return;
                }
            })
            .catch((error) => {
                setLoading(false);
                SwalBs.fire({
                    html: 'มีบางอย่างผิดพลาด กรุณาลองใหม่อีกครั้ง',
                    icon: 'error',
                    confirmButtonText: 'รับทราบ',
                    allowOutsideClick: false,
                    allowEscapeKey: false,
                    allowEnterKey: false
                });
                return;
            });
    };

    const btnDetail = (walletid) => {
        setLoading(true);
        fetch(config.crypto_url + 'admin/account/get-transaction-withdraw', {
            method: 'post',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json'
                // secret: config.secret,
            },
            body: JSON.stringify({
                token: user.token,
                walletid: walletid.toString()
            })
        })
            .then((response) => response.json())
            .then((result) => {
                setLoading(false);
                if (result.responsecode === 0) {
                    setTransaction(result);
                    handleShow();
                } else {
                    SwalBs.fire({
                        html: 'มีบางอย่างผิดพลาด กรุณาลองใหม่อีกครั้ง',
                        icon: 'warning',
                        confirmButtonText: 'รับทราบ',
                        allowOutsideClick: false,
                        allowEscapeKey: false,
                        allowEnterKey: false
                    });
                    return;
                }
            })
            .catch((error) => {
                setLoading(false);
                SwalBs.fire({
                    html: 'มีบางอย่างผิดพลาด กรุณาลองใหม่อีกครั้ง',
                    icon: 'error',
                    confirmButtonText: 'รับทราบ',
                    allowOutsideClick: false,
                    allowEscapeKey: false,
                    allowEnterKey: false
                });
                return;
            });
    };

    return (
        <div className='member-page'>
            {loading ? (
                <div className='d-flex flex-column flex-root' id='loading-section'>
                    <div className='row justify-content-center align-items-center overlay p-0 m-0' id='overlay'>
                        <div className='col col-sm-6 col-lg-12 h-100 p-0'>
                            <div className='bounce col-12'>
                                <div className='bounce1' />
                                <div className='bounce2' />
                                <div className='bounce3' />
                            </div>
                            <h1 className='col loading-txt'>กรุณารอสักครู่</h1>
                        </div>
                    </div>
                </div>
            ) : (
                <div>
                    {page === 'main' ? (
                        <UserInformationMainPage
                            init={init}
                            setInit={setInit}
                            keyword={keyword}
                            setKeyword={setKeyword}
                            btnSearch={btnSearch}
                            response={response}
                            btnView={btnView}
                        />
                    ) : page === 'info' ? (
                        <UserInformationDetailPage
                            setPage={setPage}
                            tab={tab}
                            setTab={setTab}
                            userinfo={userinfo}
                            setUserInfo={setUserInfo}
                            walletinfo={walletinfo}
                            setWalletInfo={setWalletInfo}
                            btnUpdate={btnUpdate}
                            btnDetail={btnDetail}
                        />
                    ) : null}
                </div>
            )}

            <Modal show={showModal} onHide={handleClose} size='xl' centered aria-labelledby='transaction-modal'>
                <Modal.Header closeButton>
                    <Modal.Title id='transaction-modal' className='col-11 p-0'>
                        รายละเอียดกระเป๋า
                    </Modal.Title>
                    <button type='button' className='close col-1 text-right pr-3' onClick={handleClose}>
                        <i className='ki ki-close' />
                    </button>
                </Modal.Header>
                <Modal.Body>
                    <div className='row d-flex justify-content-center'>
                        <UserInformationTablePage transaction={transaction} />
                    </div>
                </Modal.Body>
            </Modal>
        </div>
    );
}
