import React, { useEffect, useState } from 'react';

import { useSelector } from 'react-redux';

import { Modal } from 'react-bootstrap';

import DatePicker, { utils } from 'react-modern-calendar-datepicker';

import Moment from 'moment';

import Swal from 'sweetalert2/dist/sweetalert2.js';

import { ReportDonateTable } from './components';

// import $ from 'jquery';

import config from '../../../../config';

const SwalBs = Swal.mixin({
    customClass: {
        popup: 'rounded-lg',
        confirmButton: 'btn btn-success rounded-lg font-weight-bold text-uppercase px-9 py-4 mx-auto',
        cancelButton: 'btn btn-danger rounded-lg font-weight-bold text-uppercase px-9 py-4 mx-auto'
    },
    buttonsStyling: false
});

const myCustomLocale = {
    // months list by order
    months: [
        'มกราคม',
        'กุมภาพันธ์',
        'มีนาคม',
        'เมษายน',
        'พฤษภาคม',
        'มิถุนายน',
        'กรกฎาคม',
        'สิงหาคม',
        'กันยายน',
        'ตุลาคม',
        'พฤศจิกายน',
        'ธันวาคม'
    ],
    // week days by order
    weekDays: [
        {
            name: 'อาทิตย์', // used for accessibility
            short: 'อา', // displayed at the top of days' rows
            isWeekend: true // is it a formal weekend or not?
        },
        {
            name: 'จันทร์',
            short: 'จ'
        },
        {
            name: 'อังคาร',
            short: 'อ'
        },
        {
            name: 'พุธ',
            short: 'พ'
        },
        {
            name: 'พฤหัสบดี',
            short: 'พฤ'
        },
        {
            name: 'ศุกร์',
            short: 'ศ'
        },
        {
            name: 'เสาร์',
            short: 'ส',
            isWeekend: true
        }
    ],
    weekStartingIndex: 0, // just play around with this number between 0 and 6
    // return a { year: number, month: number, day: number } object
    getToday(gregorainTodayObject) {
        return gregorainTodayObject;
    },
    // return a native JavaScript date here
    toNativeDate(date) {
        return new Date(date.year, date.month - 1, date.day);
    },
    // return a number for date's month length
    getMonthLength(date) {
        return new Date(date.year, date.month, 0).getDate();
    },
    // return a transformed digit to your locale
    transformDigit(digit) {
        return digit;
    },
    nextMonth: 'Next Month', // texts in the date picker
    previousMonth: 'Previous Month', // texts in the date picker
    openMonthSelector: 'Open Month Selector', // texts in the date picker
    openYearSelector: 'Open Year Selector', // texts in the date picker
    closeMonthSelector: 'Close Month Selector', // texts in the date picker
    closeYearSelector: 'Close Year Selector', // texts in the date picker
    defaultPlaceholder: 'Select...', // texts in the date picker
    from: 'from', // for input range value
    to: 'to', // for input range value
    digitSeparator: ',', // used for input value when multi dates are selected
    yearLetterSkip: 0, // if your provide -2 for example, year will be 2 digited
    isRtl: false // is your language rtl or ltr?
};

const minimumDate = {
    year: 2022,
    month: 1,
    day: 1
};

export function ReportDonateMain(props) {
    const { user } = useSelector((state) => state.auth);

    const [ loading, setLoading ] = useState(false);
    const [ date, setDate ] = useState({
        from: null,
        to: null
    });
    const [ orderlist, setOrderlist ] = useState({});
    const [ oid, setOid ] = useState('');

    const [ showModal, setShow ] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const emptyDate = () => {
        setDate({
            from: null,
            to: null
        });
    };

    useEffect(() => {
        btnSearch();
    }, []);

    const btnSearch = () => {
        let param = {
            token: user.token
        };
        let filters = {};
        if (date.from !== null && date.to !== null) {
            filters['start'] =
                date.from.year + '-' + ('0' + date.from.month).slice(-2) + '-' + ('0' + date.from.day).slice(-2);
            filters['end'] = date.to.year + '-' + ('0' + date.to.month).slice(-2) + '-' + ('0' + date.to.day).slice(-2);
        } else {
            filters['start'] = Moment(new Date()).format('YYYY-MM-DD');
            filters['end'] = Moment(new Date()).format('YYYY-MM-DD');
        }
        if (Object.keys(filters).length > 0) {
            param['filters'] = filters;
        }
        setOrderlist({});
        setLoading(true);
        fetch(config.crypto_url + 'admin/order/donate', {
            method: 'post',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json'
                // secret: config.secret,
            },
            body: JSON.stringify(param)
        })
            .then((response) => response.json())
            .then((result) => {
                setLoading(false);
                setOrderlist(result);
            })
            .catch((error) => {
                setLoading(false);
            });
    };

    const btnApprove = () => {
        if (oid === '') {
            SwalBs.fire({
                html: 'กรุณากรอกหมายเลขรายการที่ต้องการอนุมัติให้เรียบร้อย แล้วลองใหม่อีกครั้ง',
                icon: 'warning',
                confirmButtonText: 'รับทราบ',
                allowOutsideClick: false,
                allowEscapeKey: false,
                allowEnterKey: false
            });
            return;
        }
        setLoading(true);
        fetch(config.crypto_url + 'admin/order/apporve', {
            method: 'post',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json'
                // secret: config.secret,
            },
            body: JSON.stringify({
                token: user.token,
                order_id: oid
            })
        })
            .then((response) => response.json())
            .then((result) => {
                setLoading(false);
                if (result.responsecode === 0) {
                    SwalBs.fire({
                        html: 'ดำเนินการเรียบร้อย',
                        icon: 'success',
                        confirmButtonText: 'รับทราบ',
                        allowOutsideClick: false,
                        allowEscapeKey: false,
                        allowEnterKey: false
                    }).then((event) => {
                        if (event.isConfirmed) {
                            setOid('');
                            handleClose();
                            btnSearch();
                        }
                    });
                } else {
                    SwalBs.fire({
                        html: 'มีบางอย่างผิดพลาด กรุณาลองใหม่อีกครั้ง',
                        icon: 'warning',
                        confirmButtonText: 'รับทราบ',
                        allowOutsideClick: false,
                        allowEscapeKey: false,
                        allowEnterKey: false
                    });
                    return;
                }
            })
            .catch((error) => {
                setLoading(false);
                SwalBs.fire({
                    html: 'มีบางอย่างผิดพลาด กรุณาลองใหม่อีกครั้ง',
                    icon: 'error',
                    confirmButtonText: 'รับทราบ',
                    allowOutsideClick: false,
                    allowEscapeKey: false,
                    allowEnterKey: false
                });
                return;
            });
    };

    const renderCustomInput = ({ ref }) => (
        <div className='input-group'>
            <div className='input-group-prepend'>
                <span className='input-group-text rounded-lg rounded-right-0'>
                    <span className='fas fa-calendar-alt' />
                </span>
            </div>
            <input
                type='text'
                readOnly
                ref={ref}
                value={
                    date.from && date.to ? (
                        date.from.year +
                        '/' +
                        ('0' + date.from.month).slice(-2) +
                        '/' +
                        ('0' + date.from.day).slice(-2) +
                        ' - ' +
                        date.to.year +
                        '/' +
                        ('0' + date.to.month).slice(-2) +
                        '/' +
                        ('0' + date.to.day).slice(-2)
                    ) : (
                        ''
                    )
                }
                className={`form-control rounded-lg rounded-left-0${date.from && date.to ? ' rounded-right-0' : ''}`}
            />
            {date.from &&
            date.to && (
                <div className='input-group-append cursor-pointer' onClick={emptyDate}>
                    <span className='input-group-text rounded-lg rounded-left-0 bg-danger'>
                        <span className='fas fa-times' />
                    </span>
                </div>
            )}
        </div>
    );
    return (
        <div>
            {loading ? (
                <div className='d-flex flex-column flex-root' id='loading-section'>
                    <div className='row justify-content-center align-items-center overlay p-0 m-0' id='overlay'>
                        <div className='col col-sm-6 col-lg-12 h-100 p-0'>
                            <div className='bounce col-12'>
                                <div className='bounce1' />
                                <div className='bounce2' />
                                <div className='bounce3' />
                            </div>
                            <h1 className='col loading-txt'>กรุณารอสักครู่</h1>
                        </div>
                    </div>
                </div>
            ) : (
                <div className='row main-page'>
                    <div className='col-12 row m-0 mb-5 justify-content-end'>
                        <button type='button' className='btn btn-primary rounded-lg' onClick={handleShow}>
                            <i className='fas fa-check' /> อนุมัติรายการ
                        </button>
                    </div>
                    <div className='col-12'>
                        <div className='card rounded-lg'>
                            <div className='card-body'>
                                <div className='row form-group'>
                                    <div className='col-12 col-md-3 form-group'>
                                        <div className='form-group mb-0'>
                                            <label>วันที่บริจาค</label>
                                            <DatePicker
                                                value={date}
                                                onChange={setDate}
                                                renderInput={renderCustomInput}
                                                minimumDate={minimumDate}
                                                maximumDate={utils().getToday()}
                                                locale={myCustomLocale}
                                                wrapperClassName='col-12 p-0'
                                                calendarClassName='responsive-calendar'
                                                calendarPopperPosition='bottom'
                                                shouldHighlightWeekends
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className='row'>
                                    <div className='col-12 text-right'>
                                        <button
                                            type='button'
                                            onClick={btnSearch}
                                            className='btn btn-sm btn-primary rounded-lg'
                                        >
                                            <i className='far fa-dot-circle' /> ค้นหา
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <ReportDonateTable orderlist={orderlist} />
                </div>
            )}

            <Modal show={showModal} onHide={handleClose} size='xl' centered aria-labelledby='approve-order-modal'>
                <Modal.Header closeButton>
                    <Modal.Title id='approve-order-modal' className='col-11 p-0'>
                        อนุมัติรายการ
                    </Modal.Title>
                    <button type='button' className='close col-1 text-right pr-3' onClick={handleClose}>
                        <i className='ki ki-close' />
                    </button>
                </Modal.Header>
                <Modal.Body>
                    <div className='row d-flex justify-content-center'>
                        <div className='col-sm-12 d-flex justify-content-between py-5'>
                            <div className='row form-group col-12 p-0 m-0'>
                                <div className='col-12 col-md-3 form-group'>
                                    <div className='form-group mb-0'>
                                        <label>หมายเลขรายการที่ต้องการอนุมัติ</label>
                                        <input
                                            type='text'
                                            className='form-control rounded-lg'
                                            value={oid}
                                            onChange={(e) => setOid(e.target.value)}
                                            onKeyPress={(e) => {
                                                if (e.key === 'Enter') {
                                                    btnApprove();
                                                }
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='col-sm-12 d-flex justify-content-end pt-5'>
                            <button type='button' className='btn btn-primary rounded-lg' onClick={btnApprove}>
                                <i className='fas fa-check' /> ยืนยัน
                            </button>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </div>
    );
}
