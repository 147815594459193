/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router';
import { NavLink } from 'react-router-dom';
import SVG from 'react-inlinesvg';
import { toAbsoluteUrl, checkIsActive } from '../../../../_helpers';

export function AsideMenuList({ layoutProps }) {
    const { user } = useSelector((state) => state.auth);
    const location = useLocation();
    const getMenuItemActive = (url, hasSubmenu = false) => {
        return checkIsActive(location, url) ? ` ${!hasSubmenu && 'menu-item-active'} menu-item-open ` : '';
    };

    return (
        <ul className={`menu-nav ${layoutProps.ulClasses}`}>
            {/*begin::1 Level*/}
            <li className={`menu-item ${getMenuItemActive('/dashboard', false)}`} aria-haspopup='true'>
                <NavLink className='menu-link' to='/dashboard'>
                    <span className='svg-icon menu-icon'>
                        <SVG src={toAbsoluteUrl('/media/svg/icons/Design/Layers.svg')} />
                    </span>
                    <span className='menu-text'>Dashboard</span>
                </NavLink>
            </li>
            {/*end::1 Level*/}

            {/*begin::1 Level*/}
            <li className={`menu-item ${getMenuItemActive('/user/information', false)}`} aria-haspopup='true'>
                <NavLink className='menu-link' to='/user/information'>
                    <span className='svg-icon menu-icon'>
                        <SVG src={toAbsoluteUrl('/media/svg/icons/Communication/Group.svg')} />
                    </span>
                    <span className='menu-text'>ผู้ใช้งานระบบ</span>
                </NavLink>
            </li>
            {/*end::1 Level*/}

            {/* Settings */}
            {/*begin::1 Level*/}
            <li
                className={`menu-item menu-item-submenu ${getMenuItemActive('/report', true)}`}
                aria-haspopup='true'
                data-menu-toggle='hover'
            >
                <NavLink className='menu-link menu-toggle' to='/report'>
                    <span className='svg-icon menu-icon'>
                        <SVG title='ตั้งค่า' src={toAbsoluteUrl('/media/svg/icons/Files/File.svg')} />
                    </span>
                    <span className='menu-text'>รายงาน</span>
                    <i className='menu-arrow' />
                </NavLink>
                <div className='menu-submenu '>
                    <i className='menu-arrow' />
                    <ul className='menu-subnav'>
                        <li className='menu-item  menu-item-parent' aria-haspopup='true'>
                            <span className='menu-link'>
                                <span className='menu-text'>รายงาน</span>
                            </span>
                        </li>
                        <li className={`menu-item ${getMenuItemActive('/report/donate')}`} aria-haspopup='true'>
                            <NavLink className='menu-link' to='/report/donate'>
                                <i className='menu-bullet menu-bullet-dot'>
                                    <span />
                                </i>
                                <span className='menu-text'>บริจาค</span>
                            </NavLink>
                        </li>
                        <li className={`menu-item ${getMenuItemActive('/report/withdraw')}`} aria-haspopup='true'>
                            <NavLink className='menu-link' to='/report/withdraw'>
                                <i className='menu-bullet menu-bullet-dot'>
                                    <span />
                                </i>
                                <span className='menu-text'>ถอนเหรียญ</span>
                            </NavLink>
                        </li>
                    </ul>
                </div>
            </li>
            {/*end::1 Level*/}

            <br />

            {/* Logout */}
            {/*begin::1 Level*/}
            <li className={`menu-item ${getMenuItemActive('/logout', false)}`} aria-haspopup='true'>
                <NavLink className='menu-link' to='/logout'>
                    <span className='svg-icon menu-icon'>
                        <SVG title='ออกจากระบบ' src={toAbsoluteUrl('/media/svg/icons/Electric/Shutdown.svg')} />
                    </span>
                    <span className='menu-text'>ออกจากระบบ</span>
                </NavLink>
            </li>
            {/*end::1 Level*/}
        </ul>
    );
}
