import React from 'react';

import { OverlayScrollbarsComponent } from 'overlayscrollbars-react';

export function UserInformationMainPage(props) {
    return (
        <div className='row main-page'>
            <div className='col-12'>
                <div className='card rounded-lg'>
                    <div className='card-body'>
                        <div className='row'>
                            <div className='col-12 p-0'>
                                <div className='form-group mb-0'>
                                    <label>กรุณากรอกรายละเอียดที่ต้องการค้นหา</label>
                                    <div className='input-group'>
                                        <input
                                            type='text'
                                            className='form-control rounded-lg rounded-right-0'
                                            value={props.keyword}
                                            onChange={(e) => props.setKeyword(e.target.value)}
                                            onKeyPress={(event) => {
                                                if (event.key === 'Enter') {
                                                    props.btnSearch();
                                                }
                                            }}
                                        />
                                        <div className='input-group-append cursor-pointer' onClick={props.btnSearch}>
                                            <span className='input-group-text rounded-lg rounded-left-0'>
                                                <span className='fa fa-search' />
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {!props.init && (
                <div className='col-12 mt-5 search-result'>
                    {Object.keys(props.response).length > 0 ? (
                        <div>
                            {props.response.responsecode === 0 ? (
                                <OverlayScrollbarsComponent className='max-h-400px'>
                                    <div className='card rounded-lg'>
                                        <div className='card-body'>
                                            {props.response.info.map((object, index) => {
                                                return (
                                                    <div
                                                        className='search-result new-job bg-hover-success cursor-pointer my-3'
                                                        onClick={() => props.btnView(object)}
                                                        key={index}
                                                    >
                                                        {/* <div className='badge'>ปัจจุบัน</div> */}
                                                        <div className='content p-0'>
                                                            <h2>
                                                                <div className='text-dark'>{object.personal_id}</div>
                                                                <div className='text-dark font-size-h4'>
                                                                    {object.first_name === '' ? '{ชื่อ}' : object.first_name}{' '}
                                                                    {object.last_name === '' ? '{นามสกุล}' : object.last_name}
                                                                </div>
                                                            </h2>
                                                            <div className='actions'>
                                                                <span className='date'>
                                                                    ลงทะเบียนเมื่อวันที่ {object.created_date}
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                );
                                            })}
                                        </div>
                                    </div>
                                </OverlayScrollbarsComponent>
                            ) : (
                                <div className='row bg-white align-items-center col-12 p-0 m-0 min-h-400px rounded-lg'>
                                    <div className='text-center col-12 p-0'>
                                        <i className='text-dark-75 fas fa-exclamation-circle fa-5x' />
                                        <h1 className='col mt-5'>ไม่พบข้อมูล</h1>
                                    </div>
                                </div>
                            )}
                        </div>
                    ) : (
                        <div className='row bg-white align-items-center col-12 p-0 m-0 min-h-400px rounded-lg'>
                            <div className='text-center col-12 p-0'>
                                <i className='text-dark-75 fas fa-exclamation-circle fa-5x' />
                                <h1 className='col mt-5'>ไม่พบข้อมูล</h1>
                            </div>
                        </div>
                    )}
                </div>
            )}
        </div>
    );
}
