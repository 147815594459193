import React, { useEffect } from 'react';

import $ from 'jquery';

export function UserInformationTablePage(props) {
    useEffect(
        () => {
            $(document).ready(function() {
                if (Object.keys(props.transaction).length > 0) {
                    if (props.transaction.responsecode === 0) {
                        $('#transaction-table').DataTable({
                            data: props.transaction.transaction,
                            lengthChange: false,
                            pageLength: 5,
                            responsive: true,
                            dom: 'Bfrtip',
                            buttons: [ 'excel' ],
                            order: [ [ 0, 'desc' ] ],
                            language: {
                                paginate: {
                                    previous: 'ก่อนหน้า',
                                    next: 'ถัดไป'
                                },
                                info: 'แสดง _START_ ถึง _END_ จาก _TOTAL_ รายการ',
                                infoEmpty: 'แสดง _START_ ถึง _END_ จาก _TOTAL_ รายการ',
                                emptyTable: 'ไม่มีข้อมูลที่ต้องการค้นหาจากระบบ',
                                infoFiltered: '(ค้นหาจากทั้งหมด _MAX_ รายการ)',
                                zeroRecords: 'ไม่พบข้อมูลที่ต้องการค้นหาจากคำสำคัญ'
                            },
                            oLanguage: {
                                sSearch: 'คำสำคัญ'
                            },
                            columns: [
                                {
                                    title: 'รายการที่',
                                    data: function(value) {
                                        if (value.id !== null && value.id !== '') {
                                            return value.id;
                                        } else {
                                            return '-';
                                        }
                                    }
                                },
                                {
                                    title: 'ประเภทการทำรายการ',
                                    className: 'dt-body-center',
                                    data: function(value) {
                                        if (value.trans_type !== null && value.trans_type !== '') {
                                            if (value.trans_type === 'inc') {
                                                if (value.personal_id === '') {
                                                    return '<span class="label label-success label-pill label-inline mx-auto my-auto">ฝาก</span>';
                                                } else {
                                                    return '<span class="label label-success label-pill label-inline mx-auto my-auto">รับ</span>';
                                                }
                                            } else if (value.trans_type === 'dec') {
                                                if (value.personal_id === '') {
                                                    return '<span class="label label-danger label-pill label-inline mx-auto my-auto">ถอน</span>';
                                                } else {
                                                    return '<span class="label label-danger label-pill label-inline mx-auto my-auto">โอน</span>';
                                                }
                                            }
                                        } else {
                                            return '-';
                                        }
                                    }
                                },
                                {
                                    title: 'สถานะรายการ',
                                    data: function(value) {
                                        if (value.status !== null && value.status !== '') {
                                            if (value.status === 'pending') {
                                                return '<span class="label label-light-dark label-pill label-inline mx-auto my-auto">รอการดำเนินการ</span>';
                                            } else if (value.status === 'cancel') {
                                                return '<span class="label label-warning label-pill label-inline mx-auto my-auto">ยกเลิก</span>';
                                            } else if (value.status === 'success') {
                                                return '<span class="label label-success label-pill label-inline mx-auto my-auto">ดำเนินการแล้ว</span>';
                                            }
                                        } else {
                                            return '-';
                                        }
                                    }
                                },
                                {
                                    title: 'ปลายทาง',
                                    data: function(value) {
                                        if (value.personal_id !== null && value.personal_id !== '') {
                                            return (
                                                value.first_name +
                                                ' ' +
                                                value.last_name +
                                                ' (' +
                                                value.personal_id +
                                                ')'
                                            );
                                        } else {
                                            return '-';
                                        }
                                    }
                                },
                                {
                                    title: 'จำนวนเหรียญ',
                                    data: function(value) {
                                        if (value.amount !== null && value.amount !== '') {
                                            return value.amount;
                                        } else {
                                            return '-';
                                        }
                                    }
                                },
                                {
                                    title: 'ยอดเหรียญคงเหลือก่อนทำรายการ',
                                    data: function(value) {
                                        if (
                                            value.source_before_balance !== null &&
                                            value.source_before_balance !== ''
                                        ) {
                                            return value.source_before_balance;
                                        } else {
                                            return '-';
                                        }
                                    }
                                },
                                {
                                    title: 'ยอดเหรียญคงเหลือปลายทางก่อนทำรายการ',
                                    data: function(value) {
                                        if (value.dest_before_balance !== null && value.dest_before_balance !== '') {
                                            return value.dest_before_balance;
                                        } else {
                                            return '-';
                                        }
                                    }
                                },
                                {
                                    title: 'วันที่ทำรายการ',
                                    data: function(value) {
                                        if (value.created_date !== null && value.created_date !== '') {
                                            return value.created_date;
                                        } else {
                                            return '-';
                                        }
                                    }
                                },
                                {
                                    title: 'วันที่อนุมัติ',
                                    data: function(value) {
                                        if (value.success_date !== null && value.success_date !== '') {
                                            return value.success_date;
                                        } else {
                                            return '-';
                                        }
                                    }
                                },
                                {
                                    title: 'หมายเหตุ',
                                    data: function(value) {
                                        if (value.remark !== null && value.remark !== '') {
                                            return value.remark;
                                        } else {
                                            return '-';
                                        }
                                    }
                                }
                            ]
                        });
                    }
                }
            });
        },
        [ props.transaction ]
    );

    return (
        <div className='col-12 my-5'>
            <div className='card rounded-lg'>
                <div className='card-body'>
                    {Object.keys(props.transaction).length <= 0 ? (
                        <div className='row justify-content-center align-items-center bg-white cart-box min-h-400px'>
                            <div className='col-12 text-center'>
                                <i className='text-dark-75 fas fa-exclamation-circle fa-5x' />
                                <h3 className='col mt-5'>มีบางอย่างผิดพลาด กรุณาลองใหม่อีกครั้ง</h3>
                            </div>
                        </div>
                    ) : (
                        <div>
                            {props.transaction.responsecode !== 0 ? (
                                <div className='row justify-content-center align-items-center bg-white cart-box min-h-400px'>
                                    <div className='col-12 text-center'>
                                        <i className='text-dark-75 fas fa-exclamation-circle fa-5x' />
                                        <h3 className='col mt-5'>
                                            ไม่พบข้อมูลที่ต้องการค้นหาจากรายละเอียดที่ต้องการค้นหา กรุณาลองใหม่อีกครั้ง
                                        </h3>
                                    </div>
                                </div>
                            ) : (
                                <div className='row'>
                                    <div className='col-12 p-0'>
                                        <table id='transaction-table' className='table table-striped table-bordered' />
                                    </div>
                                </div>
                            )}
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
}
