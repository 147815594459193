import React from 'react';

import { Nav, Tab } from 'react-bootstrap';

import { UserInformationEditPage, UserInformationInformationPage } from './index';

export function UserInformationDetailPage(props) {
    const btnBack = () => {
        props.setPage('main');
        props.setTab('information');
        props.setUserInfo({});
        props.setWalletInfo([]);
    };

    const handleSelect = (tab) => {
        // if (tab === 'stock') {
        //     props.getOrder(props.userinfo.info.id.toString(), '1');
        // } else if (tab === 'funeral') {
        //     props.getOrder(props.userinfo.info.id.toString(), '2');
        // } else {
        props.setTab(tab);
        // }
    };
    return (
        <div className='col-12'>
            <div className='card rounded-lg'>
                <div className='card-header bg-secondary p-4 rounded-lg rounded-bottom-0'>
                    <div className='row justify-content-between m-0'>
                        <button type='button' className='btn btn-link-primary' onClick={btnBack}>
                            <i className='fas fa-angle-double-left icon-2x py-3' />
                            ย้อนกลับ
                        </button>
                    </div>
                </div>
                <div className='card-body rounded-lg rounded-top-0'>
                    <div className='d-inline-block mb-5'>
                        <span>
                            <div>
                                <span className='d-inline-block'>ข้อมูลสมาชิก : Information</span>
                            </div>
                            <div>
                                <h1>
                                    {props.userinfo.first_name === '' ? '{ชื่อ}' : props.userinfo.first_name}{' '}
                                    {props.userinfo.last_name === '' ? '{นามสกุล}' : props.userinfo.last_name}
                                </h1>
                            </div>
                        </span>
                    </div>
                    <Tab.Container defaultActiveKey={props.tab} onSelect={handleSelect}>
                        <div className='row'>
                            <div className='col-12 p-0'>
                                <Nav fill variant='tabs'>
                                    <Nav.Item>
                                        <Nav.Link eventKey='information'>รายละเอียดทั่วไป</Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item>
                                        <Nav.Link eventKey='edit'>แก้ไขหมายเลขกระเป๋านอกระบบ</Nav.Link>
                                    </Nav.Item>
                                </Nav>
                            </div>
                            <div className='col-12 p-0'>
                                <Tab.Content>
                                    <Tab.Pane eventKey='information' className='p-5 bg-gray-300'>
                                        <UserInformationInformationPage
                                            info={props.userinfo}
                                            wallet={props.walletinfo}
                                            btnDetail={props.btnDetail}
                                        />
                                    </Tab.Pane>
                                    <Tab.Pane eventKey='edit' className='p-5 bg-gray-300'>
                                        <UserInformationEditPage
                                            wallet={props.walletinfo}
                                            btnUpdate={props.btnUpdate}
                                        />
                                    </Tab.Pane>
                                </Tab.Content>
                            </div>
                        </div>
                    </Tab.Container>
                </div>
            </div>
        </div>
    );
}
