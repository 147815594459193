import React from 'react';

import NumberFormat from 'react-number-format';

import { OverlayScrollbarsComponent } from 'overlayscrollbars-react';

// import { toAbsoluteUrl } from '../../../../../../_metronic/_helpers';

export function UserInformationInformationPage(props) {
    const btnCopy = (id) => {
        var copyText = document.getElementById(id);
        // console.log(copyText);
        copyText.select();
        copyText.setSelectionRange(0, 99999);
        document.execCommand('copy');
    };

    return (
        <div className='row m-0'>
            <div className='col-12 col-md-6 p-0 mb-7 mb-md-0'>
                <h2 className='d-inline-block mb-5'>รายละเอียดสมาชิก</h2>
                <div className='row justify-content-between m-0'>
                    <div className='col-12 form-group'>
                        <h6 className='d-block m-0'> ชื่อ : {props.info.first_name} </h6>
                    </div>
                    <div className='col-12 form-group'>
                        <h6 className='d-block m-0'> นามสกุล : {props.info.last_name} </h6>
                    </div>
                    <div className='col-12 form-group'>
                        <h6 className='d-block m-0'>
                            {' '}
                            ประเภทข้อมูลประจำตัว : {props.info.personal_id.length === 13 ? 'ไทย' : 'ต่างชาติ'}{' '}
                        </h6>
                    </div>
                    <div className='col-12 form-group'>
                        <h6 className='d-block m-0'>
                            {' '}
                            หมายเลข{props.info.personal_id.length === 13 ? 'บัตรประชาชน' : 'พาสปอร์ต'} :{' '}
                            {props.info.personal_id}{' '}
                        </h6>
                    </div>
                    <div className='col-12 form-group'>
                        <h6 className='d-block m-0'> เบอร์ติดต่อ : {props.info.contact_number} </h6>
                    </div>
                    {/* <div className='col-12 col-md-6 form-group'>
                        <h6 className='d-block m-0'>
                            {' '}
                            วัน/เดือน/ปีเกิด :{' '}
                            {Moment(new Date(props.info.birthdate))
                                .add(543, 'year')
                                .locale('th')
                                .format('DD/MMMM/YYYY')}{' '}
                        </h6>
                    </div> */}
                    {/* <div className='col-12 form-group'>
                        <h6 className='d-block m-0'> ที่อยู่ : {address} </h6>
                    </div> */}
                </div>
            </div>
            <div className='col-12 col-md-6 p-0'>
                <h2 className='d-inline-block mb-5'>รายละเอียดกระเป๋าเหรียญ</h2>
                {props.wallet.length > 0 ? (
                    <OverlayScrollbarsComponent className='max-h-400px'>
                        <div className='row justify-content-between m-0 p-5'>
                            {props.wallet.map((object, index, arr) => {
                                return (
                                    <div
                                        className={`card card-custom rounded-lg bg-white bg-hover-success wallet-account col-12 p-0${arr.length -
                                            1 !==
                                        index
                                            ? ' gutter-b'
                                            : ''}`}
                                        key={index}
                                        onClick={() => props.btnDetail(object.id)}
                                    >
                                        <div className='card-body'>
                                            <div className='row justify-content-between m-0'>
                                                <div className='col-12 col-md-6 form-group'>
                                                    <h6 className='font-weight-bolder d-block m-0'>
                                                        {object.account_type === 'bnb' ? (
                                                            'BNB'
                                                        ) : object.account_type === 'starverse' ? (
                                                            'StarVerse'
                                                        ) : null}
                                                    </h6>
                                                </div>
                                                <div className='col-12 col-md-6 form-group'>
                                                    <h6 className='d-block m-0'>
                                                        {' '}
                                                        จำนวนเหรียญ :{' '}
                                                        <span className='text-muted coin-balance'>
                                                            <NumberFormat
                                                                value={parseFloat(object.balance)}
                                                                displayType={'text'}
                                                                decimalScale={2}
                                                                fixedDecimalScale={true}
                                                                thousandSeparator={true}
                                                                // suffix={' บาท'}
                                                            />
                                                        </span>
                                                    </h6>
                                                </div>
                                                <div className='col-12 form-group'>
                                                    <h6 className='d-block m-0'>หมายเลขกระเป๋าในระบบ</h6>
                                                </div>
                                                <div className='col-12 form-group'>
                                                    <h6 className='d-block m-0'>
                                                        <div className='input-group'>
                                                            <input
                                                                className='form-control'
                                                                type='text'
                                                                defaultValue={object.address}
                                                                id={object.account_type + '_address'}
                                                                readOnly
                                                            />
                                                            <div className='input-group-append'>
                                                                <button
                                                                    type='button'
                                                                    className='btn btn-secondary'
                                                                    onClick={() =>
                                                                        btnCopy(object.account_type + '_address')}
                                                                >
                                                                    <span className='fas fa-copy' />
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </h6>
                                                </div>
                                                <div className='col-12 row mx-0 justify-content-between'>
                                                    <h6 className='d-block mx-0 form-group'>หมายเลขกระเป๋านอกระบบ</h6>
                                                    <h6 className='d-block mx-0 form-group'>
                                                        {object.withdraw_network}
                                                    </h6>
                                                </div>
                                                <div className='col-12 form-group'>
                                                    <h6 className='d-block m-0'>
                                                        <div className='input-group'>
                                                            <input
                                                                className='form-control'
                                                                type='text'
                                                                defaultValue={object.withdraw_address}
                                                                id={object.account_type + '_withdraw_address'}
                                                                readOnly
                                                            />
                                                            <div className='input-group-append'>
                                                                <button
                                                                    type='button'
                                                                    className='btn btn-secondary'
                                                                    onClick={() =>
                                                                        btnCopy(
                                                                            object.account_type + '_withdraw_address'
                                                                        )}
                                                                >
                                                                    <span className='fas fa-copy' />
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </h6>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                );
                            })}
                        </div>
                    </OverlayScrollbarsComponent>
                ) : (
                    <div className='row align-items-center col-12 p-0 m-0 min-h-100px'>
                        <div className='text-center col-12 p-0'>
                            <i className='text-dark-75 fas fa-exclamation-circle fa-5x' />
                            <h1 className='col mt-5'>ไม่พบข้อมูล</h1>
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
}
