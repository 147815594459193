import React, { useEffect } from 'react';

import $ from 'jquery';

export function ReportDonateTable(props) {
    useEffect(
        () => {
            $(document).ready(function() {
                if (Object.keys(props.orderlist).length > 0) {
                    if (props.orderlist.responsecode === 0) {
                        $('#donate-list-table').DataTable({
                            data: props.orderlist.order,
                            lengthChange: false,
                            pageLength: -1,
                            responsive: true,
                            dom: 'Bfrtip',
                            buttons: [ 'excel' ],
                            // order: [ [ 2, 'asc' ] ],
                            language: {
                                paginate: {
                                    previous: 'ก่อนหน้า',
                                    next: 'ถัดไป'
                                },
                                info: 'แสดง _START_ ถึง _END_ จาก _TOTAL_ รายการ',
                                infoEmpty: 'แสดง _START_ ถึง _END_ จาก _TOTAL_ รายการ',
                                emptyTable: 'ไม่มีข้อมูลที่ต้องการค้นหาจากระบบ',
                                infoFiltered: '(ค้นหาจากทั้งหมด _MAX_ รายการ)',
                                zeroRecords: 'ไม่พบข้อมูลที่ต้องการค้นหาจากคำสำคัญ'
                            },
                            oLanguage: {
                                sSearch: 'คำสำคัญ'
                            },
                            columns: [
                                // {
                                //     title: '#',
                                //     className: 'dt-body-center',
                                //     data: function(value) {
                                //         return `<label class="checkbox">
                                //                     <input type="checkbox" name="Checkboxes1"/>
                                //                     <span class='mr-3'></span> เลือก
                                //                 </label>`;
                                //     }
                                // },
                                // {
                                //     title: 'รายละเอียด',
                                //     className: 'dt-body-center',
                                //     data: function(value) {
                                //         // if (value.id !== null && value.id !== '') {
                                //         //     return value.id;
                                //         // } else {
                                //         //     return '-';
                                //         // }
                                //         return `<button
                                //                     type="button"
                                //                     class="btn btn-icon btn-primary view-order-info"
                                //                     data-id=${value.id}
                                //                 >
                                //                     <i class="fa fa-search"></i>
                                //                 </button>`;
                                //     }
                                // },
                                {
                                    title: 'รายการที่',
                                    data: function(value) {
                                        if (value.id !== null && value.id !== '') {
                                            return value.id;
                                        } else {
                                            return '-';
                                        }
                                    }
                                },
                                {
                                    title: 'วันที่บริจาค',
                                    data: function(value) {
                                        if (value.create_date !== null && value.create_date !== '') {
                                            return value.create_date;
                                        } else {
                                            return '-';
                                        }
                                    }
                                },
                                {
                                    title: 'สถานะรายการ',
                                    className: 'dt-body-center',
                                    data: function(value) {
                                        if (value.status !== null && value.status !== '') {
                                            if (value.status === 'pending') {
                                                return '<span class="label label-light-dark label-pill label-inline mx-auto my-auto">รอการชำระเงิน</span>';
                                            } else if (value.status === 'paid') {
                                                return '<span class="label label-primary label-pill label-inline mx-auto my-auto">ชำระแล้ว</span>';
                                            } else if (value.status === 'cancel') {
                                                return '<span class="label label-warning label-pill label-inline mx-auto my-auto">ยกเลิก</span>';
                                            } else if (value.status === 'expired') {
                                                return '<span class="label label-danger label-pill label-inline mx-auto my-auto">หมดอายุ</span>';
                                            } else if (value.status === 'completed') {
                                                return '<span class="label label-success label-pill label-inline mx-auto my-auto">ยืนยันสินค้าแล้ว</span>';
                                            }
                                        } else {
                                            return '-';
                                        }
                                    }
                                },
                                {
                                    title: 'ราคา',
                                    data: function(value) {
                                        if (value.total_gross_price !== null && value.total_gross_price !== '') {
                                            return value.total_gross_price;
                                        } else {
                                            return '-';
                                        }
                                    }
                                }
                                // {
                                //     title: 'หมายเลขคำสั่งซื้อ',
                                //     // data: "name",
                                //     data: function(value) {
                                //         if (value.id !== null && value.id !== '') {
                                //             return value.id;
                                //         } else {
                                //             return '-';
                                //         }
                                //     }
                                // },
                                // {
                                //     title: 'วันที่สั่งซื้อ',
                                //     data: function(value) {
                                //         if (value.create_date !== null && value.create_date !== '') {
                                //             return value.create_date;
                                //         } else {
                                //             return '-';
                                //         }
                                //     }
                                // },
                                // {
                                //     title: 'ช่องทางชำระเงิน',
                                //     className: 'dt-body-center',
                                //     data: function(value) {
                                //         if (value.payment_id !== null && value.payment_id !== '') {
                                //             if (value.payment_id === '1') {
                                //                 return '<span class="label label-danger label-pill label-inline mx-auto my-auto">กระเป๋าซิมปันสุข</span>';
                                //             } else {
                                //                 return '<span class="label label-primary label-pill label-inline mx-auto my-auto">คิวอาร์โค้ด</span>';
                                //             }
                                //         } else {
                                //             return '-';
                                //         }
                                //     }
                                // },
                                // {
                                //     title: 'วันที่ชำระเงิน',
                                //     data: function(value) {
                                //         if (value.paid_date !== null && value.paid_date !== '') {
                                //             return value.paid_date;
                                //         } else {
                                //             return '-';
                                //         }
                                //     }
                                // },
                                // {
                                //     title: 'สถานะคำสั่งซื้อ',
                                //     className: 'dt-body-center',
                                //     data: function(value) {
                                //         if (value.status !== null && value.status !== '') {
                                //             if (value.status === 'pending') {
                                //                 return '<span class="label label-light-dark label-pill label-inline mx-auto my-auto">รอการชำระเงิน</span>';
                                //             } else if (value.status === 'paid') {
                                //                 return '<span class="label label-primary label-pill label-inline mx-auto my-auto">ชำระแล้ว</span>';
                                //             } else if (value.status === 'cancel') {
                                //                 return '<span class="label label-warning label-pill label-inline mx-auto my-auto">ยกเลิก</span>';
                                //             } else if (value.status === 'expired') {
                                //                 return '<span class="label label-danger label-pill label-inline mx-auto my-auto">หมดอายุ</span>';
                                //             } else if (value.status === 'completed') {
                                //                 return '<span class="label label-success label-pill label-inline mx-auto my-auto">ยืนยันสินค้าแล้ว</span>';
                                //             }
                                //         } else {
                                //             return '-';
                                //         }
                                //     }
                                // },
                                // {
                                //     title: 'ราคา',
                                //     data: function(value) {
                                //         if (value.total_gross_price !== null && value.total_gross_price !== '') {
                                //             return value.total_gross_price;
                                //         } else {
                                //             return '-';
                                //         }
                                //     }
                                // }
                            ]
                        });
                    }
                }
            });
        },
        [ props.orderlist ]
    );

    return (
        <>
            <div className='col-12 my-5'>
                <div className='card rounded-lg'>
                    <div className='card-body'>
                        {Object.keys(props.orderlist).length <= 0 ? (
                            <div className='row justify-content-center align-items-center bg-white cart-box min-h-400px'>
                                <div className='col-12 text-center'>
                                    <i className='text-dark-75 fas fa-exclamation-circle fa-5x' />
                                    <h3 className='col mt-5'>มีบางอย่างผิดพลาด กรุณาลองใหม่อีกครั้ง</h3>
                                </div>
                            </div>
                        ) : (
                            <div>
                                {props.orderlist.responsecode !== 0 ? (
                                    <div className='row justify-content-center align-items-center bg-white cart-box min-h-400px'>
                                        <div className='col-12 text-center'>
                                            <i className='text-dark-75 fas fa-exclamation-circle fa-5x' />
                                            <h3 className='col mt-5'>
                                                ไม่พบข้อมูลที่ต้องการค้นหาจากรายละเอียดที่ต้องการค้นหา
                                                กรุณาลองใหม่อีกครั้ง
                                            </h3>
                                        </div>
                                    </div>
                                ) : (
                                    <div className='row'>
                                        {/* <div className='row col-12 justify-content-end m-0 mb-5 p-0'>
                                            <Dropdown className='rounded-lg'>
                                                <Dropdown.Toggle
                                                    className='rounded-lg'
                                                    id='dropdown-autoclose-true'
                                                >
                                                    คำสั่งต่างๆ
                                                </Dropdown.Toggle>

                                                <Dropdown.Menu>
                                                    <Dropdown.Item onClick={() => props.btnDownload(false)}>
                                                        ดาวน์โหลดรายการจัดส่ง
                                                    </Dropdown.Item>
                                                    <Dropdown.Item onClick={() => props.btnDownload(true)}>
                                                        ดาวน์โหลดรายการจัดส่ง (Kerry Format)
                                                    </Dropdown.Item>
                                                </Dropdown.Menu>
                                            </Dropdown>
                                        </div> */}
                                        <div className='col-12 p-0'>
                                            <table
                                                id='donate-list-table'
                                                className='table table-striped table-bordered'
                                            />
                                        </div>
                                    </div>
                                )}
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </>
    );
}
